import React from "react"

import Layout, {KiteLink} from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout title="Page Not Found">
    <p>Oops. If you entered a web address, please check it was correct.</p>
    <p>Alternatively, try the <KiteLink href="/">home page</KiteLink> instead.</p>
  </Layout>
)

export default NotFoundPage
